<template>
    <div class="login-container">
        <div class="login">
            <div class="login-content">
                <div class="logo">
                    <img src="@/assets/logo.png">
                </div>
                <h2>Willkommen Agent,</h2>
                <p>Dies ist die Hauptplattform, über die Sie täglich mit Tickets interagieren werden. Weitere Informationen finden Sie in der <a class="primary" href="#">Hilfeanleitung</a>.</p>
                <form @submit.prevent="submitForm">
                    <div class="form-control">
                        <label>E-Mail:</label>
                        <input type="text" v-model="email" name="username" autocomplete="off">
                    </div>
                    <div class="form-control">
                        <label>Passwort:</label>
                        <input type="password" v-model="password" name="password" ref="passwordInput" autocomplete="off">
                        <i class="fas fa-eye" @click="togglePassword"></i>
                    </div>
                    <div class="flex items-center mt-20">
                        <button type="submit" class="primary-btn mt-0">Anmelden</button>
                        <a href="#" class="primary block">Passwort vergessen?</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
</template>

<style lang="scss">
    .login-container {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        position: relative;
        &::after {
            position: absolute;
            content: "FarbenLöwe";
            font-weight: 900;
            font-size: 30rem;
            color: #000;
            opacity: 2.5%;
            left: 0;
            bottom: -10%;
            font-family: 'Poppins', sans-serif;
            z-index: -1;
        }
    }
    .login {
        max-width: 81.3rem;
        transform: translateY(9.3rem);
        z-index: 1;
        position: relative;
        height: min-content;
        box-shadow: 0px 4px 40px rgba(0,0,0,.05);
        .login-content {
            padding: 4.4rem 13.9rem 8.7rem 13.9rem;
            position: relative;
            background: #fff;
        }
        .logo {
            width: 8.6rem;
            img {
                width: 100%;
            }
            margin: 0 auto;
            margin-bottom: 4.6rem;
        }
        h2 {
            font-size: 18px;
        }
        p {
            font-size: 16px;
            color: #4D4D4D;
            margin-bottom: 2.7rem;
        }
        
        a {
            font-size: 16px;
            text-decoration: none;
        }

    }
</style>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        ...mapActions(['login']),
        togglePassword() {
            if(this.$refs.passwordInput.type == 'password') {
                this.$refs.passwordInput.type = 'text';
            }
            else {
                this.$refs.passwordInput.type = 'password';
            }
        },
        submitForm() {
            const user = {
                email: this.email,
                password: this.password
            };
            this.login(user);
        }
    }
}
</script>